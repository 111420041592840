.title {
    padding: 2rem;
    color: var(--main);
}

.projects-container {
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.project {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    max-width: 80%;
    margin: auto;
    text-align: left;
    border: 2px solid var(--main);
    padding: 2rem;
    border-radius: 20px;
    margin-bottom: 2rem;
    color: var(--main);
}
.project img {
    border-radius: 25px;
    margin: auto;
}

.project-text {
    
}

.header{ 
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.header h2 {
    margin: 0;
}

.technologies-container {
    display:flex;
    flex-direction: row;
    gap: 10px;
}

.technologie {
    border: 1px solid var(--main);
    padding: 0.5rem;
    border-radius: 20px;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    align-self: center;
}
.links-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.link-button {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border: 1px solid var(--main);
    border-radius: 20px;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    text-decoration: none;
    color: var(--main);
    font-weight: 600;
    justify-content: space-between;
    gap:10px;
}


.link-button svg{
    text-decoration: none;
    color: var(--main);
    font-size:30px;
}

.link-button:hover {
    transform: scale(1.1);
    color: var(--bg);
    background-color: var(--main);
}

.link-button:hover svg {
    color: var(--bg);
}



@media  screen and (max-width: 1200px) {

    .header h2 {
        font-size: 1.5rem;
    }
    
}

@media  screen and (max-width: 992px) {

    .project {
        max-width: 100%;
    }
    .header {
        flex-direction: column;
        gap: 1rem;
    }
    .link-button {
        font-size: 17px;
    }
}

@media  screen and (max-width: 768px) {
    .project {
        flex-direction: column;
        
    }

    .header h2 {
        text-align: center;
    }

    .technologies-container {
        justify-content: center;
    }

    .links-container {
        justify-content: center;
    }

    

    
}


@media  screen and (max-width: 576px) {
    .link-button {
        font-size: 15px;
        align-items: center;
    }

    .link-button svg {
        font-size: 20px;
    }
    .technologie {
        font-size: 12px;
    }
}
@media  screen and (max-width:450px) 
{
    .link-button {
        padding:0.2rem;
        font-size: 10px;
    }
    .link-button svg {
        font-size: 12px;
    }
}