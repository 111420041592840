.hero-upper-container{
    display: flex;
    flex-direction: row;
    color: var(--main);
    background-color: var(--bg);
    padding: 2rem;
    justify-content: space-between;
    padding-top: 4rem;

}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    padding: 2rem;
    max-width: 500px;
}

.hero-text h1 {
    font-weight: 800;
}
.hero-img img{
    height: 350px;
}

.hero-icons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.hero-upper-container a {
    
    text-decoration: none;
    color: var(--main);
    font-size:36px
}

.hero-lower-container{
    color: var(--main);
    background-color: var(--bg);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    justify-content: space-between;
}

.tech-stack-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    padding: 2rem;
    text-align: left;
}

.skill-icons-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.skill {
    width: 70px;
    text-align: center;
    font-size: smaller;
    margin-top: 10px
}

.skill img {
    width: 50px;
}



.buttons-container{
    width: 400px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    gap: 1.5rem;
    align-items: center;
    padding: 1.5rem;
    margin-bottom: 15px;
    justify-content: space-between;
}

.buttons-container a {
    text-decoration: none;
    color: var(--main);
    background-color: var(--bg);
    border-radius: 20px;
    padding: 0.7rem;
    font-weight: 600;
    border: 2px solid var(--main);
}

.buttons-container a:hover {
    transform: scale(1.1);
    color: var(--bg);
    background-color: var(--main);
}



@media  screen and (max-width: 1200px) {
    .tech-stack-container{
        max-width: 500px;
        display: flex;
        flex-direction: column;
    }
    
    .skill{
        width: 55px;
        font-size: x-small;
    }

    .skill img {
        width: 40px;
    }
    
}
@media screen and (max-width: 992px) {

    .hero-lower-container{
        flex-direction: column;
    }

    .tech-stack-container{
        max-width: 100%;
    }

    .skill{
        width: 70px;
    }

    .skill img {
        width: 50px;
    }

    .buttons-container{
        justify-content: space-around;

        width: 100%;
    }

}


@media screen and (max-width: 768px) {
    .hero-upper-container{
        flex-direction: column;
    }

    .hero-img img{
        height: 280px;
    }

    .hero-text {
        margin: auto;
        text-align: center;
        justify-content: center;
        max-width: 350px;
    }

    .hero-icons{
        justify-content: center;
    }

    .tech-stack-container{
        flex-direction: column;
    }

    

}

@media screen and (max-width: 566px) {
    .hero-img img{
        height: 200px;
    }

    .tech-stack-container{
        flex-direction: column;
    }

    .buttons-container {
        padding: 2rem 0;
    }

    .skill-icons-container {
        flex-direction: column;
    }

    .skill{ 
        margin: 0 10px;
    }

    .buttons-container button {
        font-size: 12px;
    }

}