.about {
    background-color: var(--secondary);
}

.about-terminal {
    padding: 3rem;
    text-align: left;
    margin: auto;
    font-family: "Terminal", sans-serif;
    max-width: 70%;
    
}

.about-text h2 {
    margin: 1rem 0;
}

.window-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    color: var(--main);
    background-color: var(--bg);
    border: 2px solid var(--main);
    padding: 0.5rem 1rem;
    border-radius: 20px 20px 0 0;
    
}

.tab-route {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.tab-icons {
    font-size: 24px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
}

.window-tab h4 {
    margin: 0;
}

.terminal-window {
    padding: 1rem;
    background-color: var(--main);
    color: var(--bg);
    border-radius: 0 0 20px 20px;
}


@media screen and (max-width: 992px) {
    .about-terminal {
        max-width: 100%;
        padding: 3rem 1rem;
    }
}


@media screen and (max-width: 768px) {
    .tab-route h4{
        font-size: 1rem;
    }

    .tab-icons svg{
        height: 12px;
        width: 12px;
    }
}

@media screen and (max-width: 492px){

    .tab-route h4{
        font-size: 0.7rem;
    }

    .tab-icons svg{
        height: 10px;
        width: 10px;
    }

}