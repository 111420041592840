/* src/Components/Navbar/Navbar.css */
.navbar {
    background-color: var(--secondary);
    color: var(--main);
    font-weight: 600;
    display: flex;
    position: sticky !important;
    top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: auto;
    
}

.navbar-menu {
    display: flex;
    align-items: center;
}

.navbar-item {
    margin-right: 1rem;
    text-decoration: none;
    color: var(--main);
}

.navbar-item:hover {
    text-decoration: underline;
}

.switch-button {
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid var(--main);
    background-color: var(--secondary);
    color: var(--main);

}

.switch-container {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.switch-container {
    display: flex;
    align-items: center;
}


.switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 60px;
    height: 34px;
    margin: 0 10px; 
}


.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D2E32;
    transition: .4s;
    border-radius: 34px;
}


.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--secondary);
    transition: .4s;
    border-radius: 50%;
}


input:checked + .slider {
    background-color: #F9F9F9
}

input:checked + .slider:before {
    transform: translateX(26px);
}


.icon {
    z-index: 1; 
    font-size: 1.5rem; 
    color: var(--main) 
}

.sun-icon {
    margin-right: 5px; 
}

.moon-icon {
    margin-left: 5px; 
}

@media screen and (max-width: 992px) {

    .switch-container {
        margin: 0;
    }
}


@media screen and (max-width: 492px) {
    .switch{
        width: 35px;
        height: 20px;
        margin: 0;
    }
    
    .slider:before {
        height: 13px;
        width: 13px; 
    }

    input:checked + .slider:before {
        transform: translateX(13px);
    }

    .icon {
        font-size: 0.8rem; 
    }
}