:root {
    --main: #000000;
    --secondary: #F9F9F9;
    --bg: #ffffff;
    
}

body.dark {
    --main: #ffffff;
    --secondary: #2D2E32;
    --bg: #000000; 
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    background-color: var(--bg);
    color: var(--main);
}

.App {
    text-align: center;
    background-color: var(--bg);
}

@font-face {
    font-family: "Terminal";
    src: url('./fonts/VT323-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
    



